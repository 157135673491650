<template>
  <div>
    <el-form ref="form" :model="form" label-width="200px" :rules="rules">
      <el-form-item :label="$t('System.RunConfig.SMTPServiceAddress')" prop="smtpUrl">
        <el-input v-model="form.smtpUrl" size="small" style="width: 250px"></el-input>
      </el-form-item>
      <el-form-item :label="$t('System.RunConfig.Port')" prop="smtpPort">
        <el-input v-model="form.smtpPort" size="small" style="width: 250px"></el-input>
      </el-form-item>
      <el-form-item :label="$t('System.RunConfig.WhetherToUseSSL')">
        <el-checkbox style="width: 22%" value="0" v-model="form.isSsl" true-label="1" false-label="0"></el-checkbox>
      </el-form-item>
      <el-form-item :label="$t('System.RunConfig.EmailAccount')" prop="email">
        <el-input style="width: 250px" size="small" v-model="form.email"></el-input>
      </el-form-item>
      <el-form-item :label="$t('System.RunConfig.EmailPassword')">
        <el-input style="width: 250px" size="small" v-model="form.emailPass" :placeholder="$t('System.RunConfig.passPlaceholder')" type="password"></el-input>
      </el-form-item>
      <el-form-item :label="$t('System.RunConfig.TestReceivingEmail')">
        <el-input v-model="form.testAddress" size="small" style="width: 250px"></el-input>
      </el-form-item>
      <el-form-item :label="$t('System.RunConfig.AlarmSubmissionEmail')">
        <el-input v-model="form.reportTo" size="small" style="width: 250px" :placeholder="$t('System.RunConfig.reportToPlaceholder')"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" @click="submitForm('form')" size="small">{{ $t("public.Save") }}</el-button>
        <el-button type="primary" @click="emailtest" size="small" style="margin-left: 5px">{{ $t("System.RunConfig.test") }}</el-button>
      </el-form-item>
    </el-form>
    <dialog-info :dialogInfo="visibaelFlag" @handleClose="handleClose" @determine="determine" :titleInfo="titleInfo" :width="'240px'" />
  </div>
</template>

<script>
import dialogInfo from '@/components/promptMessage/index'
export default {
  components: { dialogInfo },
  data() {
    function validateIP(rule, value, callback) {
      var IPRegex =
        /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/
      let valdata = value.split('\n')
      valdata.forEach((item) => {
        if (!IPRegex.test(item)) {
          callback(new Error('请输入正确的IP地址,如：smtp.163.com'))
          return
        }
      })
      callback()
    }
    // 邮箱验证
    var validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('System.RunConfig.emailVoid')))
      } else {
        if (value !== '') {
          var reg =
            /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
          if (!reg.test(value)) {
            callback(new Error(this.$t('System.RunConfig.emailEffective')))
          }
        }
        callback()
      }
    }
    return {
      visibaelFlag: false, //弹框
      titleInfo: '',
      form: {
        smtpUrl: '', //SMTP服务地址
        smtpPort: '', //端口
        isSsl: 0, //是否启用ssl  默认为0  0是不勾选
        email: '', //邮箱账号
        emailPass: '', //邮箱密码
        testAddress: '', //测试接收邮箱
        reportTo: '' //告警报送邮箱
      },
      rules: {
        // 地址
        smtpUrl: [
          { required: true, trigger: 'blur', message: this.$t('System.RunConfig.smtpUrlVoid') }
        ],
        // smtpUrl: [
        //   { validator: validateIP, trigger: "blur" },
        //   { required: true, trigger: "blur", message: "请输入IP地址" },
        // ],
        // 端口
        smtpPort: [
          { required: true, message: this.$t('System.RunConfig.portReg'), trigger: 'blur' }
          // { min: 1, max: 65535, message: "最长输入5个字符", trigger: "blur" },
        ],
        email: [
          // { required: true, message: "请输入邮箱账号", trigger: "blur" },
          { required: true, validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error(this.$t('System.RunConfig.emailVoid')))
              } else {
                if (value !== '') {
                  // var reg =/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
                  if (!/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)) {
                    callback(new Error(this.$t('System.RunConfig.emailEffective')))
                  }
                }
                callback()
              }
            },
            trigger: 'blur'
          }
        ]
      }
    }
  },

  mounted() {
    this.SMSlist()
  },

  methods: {
    // 进入页面接口请求
    async SMSlist() {
      var param = {}
      // console.log(data);
      const res = await this.$axios.post(
        'httpServe/email/gateway/selectOne',
        param,
        true
      )
      console.log(res)
      this.form = res.data
      console.log(res.data, 'jjjjjj')
    },
    // 保存
    async emailadd() {
      var secretKey = $.getSecretKey(this.form.email)
      var param = {
        smtpUrl: this.form.smtpUrl,
        smtpPort: this.form.smtpPort,
        email: this.form.email,
        emailPass: this.$utils.common.encrypt(
          this.form.emailPass,
          'SM4',
          secretKey
        ),
        isSSL: this.form.isSSL,
        testAddress: this.form.testAddress,
        reportTo: this.form.reportTo
      }
      console.log(param, '---邮件保存')
      const res = await this.$axios.post(
        'httpServe/email/gateway/update',
        param,
        true
      )
      console.log(res)
      // this.titleInfo = "保存成功";
      // this.visibaelFlag = true;
      this.$message({
        message: this.$t('public.SuccessfullySaved'),
        type: 'success',
        offset: 100
      })
    },
    // 测试
    async emailtest() {
      if (this.form.emailPass == undefined || this.form.emailPass == '') {
        console.log(this.form.emailPass)
        this.$message({
            message: this.$t('System.RunConfig.TestEmailFailed'),
            type: "error",
            offset: 100,
          });
        return false;
      } 
      var secretKey = $.getSecretKey(this.form.email);
      let params = {
        smtpUrl: this.form.smtpUrl,
        smtpPort: this.form.smtpPort,
        email: this.form.email,
        emailPass: this.$utils.common.encrypt(
          this.form.emailPass,
          'SM4',
          secretKey
        ),
        isSSL: this.form.isSSL,
        testAddress: this.form.testAddress,
        reportTo: this.form.reportTo
      }
      console.log(params, '---测试')
      const res = await this.$axios.post(
        '/httpServe/email/gateway/test',
        params,
        true
      )
      if (res.code == 200) {
        this.$message({
          message: this.$t('System.RunConfig.checkEmail'),
          type: 'success',
          offset: 100
        })
      }
      console.log(res, '-----ceshi')

      // this.titleInfo = "请查看填写的测试号码是否收到测试短信，若收到则测试成功！";
      // this.visibaelFlag = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        // this.save();
        if (valid) {
          this.emailadd()
        } else {
          console.log('error submit!!')
          return false
        }
      })
      // this.$emit("")
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    handleClose() {
      this.visibaelFlag = false
    },
    determine() {
      this.visibaelFlag = false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
::v-deep .el-form-item {
  margin-bottom: 17px !important;
}
</style>
