<template>
  <div>
    <el-form ref="form" :model="form" label-width="200px" :rules="rules">
      <el-form-item :label="$t('System.RunConfig.sms')">
        <el-select v-model="form.plat" :placeholder="$t('System.RunConfig.selectPlaceholder')" size="small" style="width: 200px"  @change="change" >
          <el-option v-for="item in typeOptions" :key="item.type"  :label="$t(`System.RunConfig.typeOptions.${item.label}`)" :value="item.type"></el-option>
          <!-- <el-option label="金笛" value="jindi"></el-option> -->
        </el-select>
      </el-form-item>
      <!-- <div v-if="form.plat=='jindi'"> -->
      <div v-if="typeFlag1">
        <el-form-item :label="$t('System.RunConfig.serverAddress')" prop="address"  key="address">
          <el-input v-model="form.address" size="small" style="width: 250px"></el-input>
        </el-form-item>
        <el-form-item :label="$t('System.RunConfig.Port')" prop="port" key="port">
          <el-input v-model.trim="form.port" size="small" placeholder="1 ~ 65535" style="width: 250px" onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('System.RunConfig.accountNumber')">
          <el-input v-model="form.account" size="small" style="width: 250px"></el-input>
        </el-form-item>
        <el-form-item :label="$t('System.IosConfig.MdmCertificate.Password')+'：'" >
          <el-input style="width: 250px" size="small" v-model="form.pass" :placeholder="$t('System.RunConfig.passPlaceholder')" type="password"></el-input>
        </el-form-item>
      </div>


      <!--新增支持配置阿里云短信网关 阿里云短信 v-if用来切换的元素是会被销毁的，导致了v-if内的表单项，在mounted时期没有进行渲染  设置 v-if 的元素加上key值有多个el-form-item，那么每个el-form-item上都需要加上key，否则其他el-form-item上的校验失效-->
      <el-form-item label="AccessKey ID：" prop="AccessKeyID" v-if="typeFlag2" key="AccessKeyID">
        <el-input v-model="form.AccessKeyID" size="small"  :placeholder="$t('System.RunConfig.AKIDPlaceholder')"  style="width: 250px"></el-input>
      </el-form-item>
      <el-form-item label="AccessKey Secret："  prop="AccessKeySecret"  v-if="typeFlag2"  key="AccessKeySecret">
        <el-input v-model="form.AccessKeySecret" size="small" style="width: 250px"  :placeholder="$t('System.RunConfig.AKSecretPlaceholder')" ></el-input>
      </el-form-item>
      <el-form-item :label="$t('System.RunConfig.Sign')" prop="Sign"  v-if="typeFlag2"  key="Sign">
        <el-input style="width: 250px" size="small" maxlength="12"  v-model="form.Sign" :placeholder="$t('System.RunConfig.SignPlaceholder')" ></el-input>
      </el-form-item>
      <el-form-item :label="$t('System.RunConfig.TestNumber')"  prop="mobile"  key="mobile">
        <el-input v-model="form.mobile"  maxlength="11" size="small" style="width: 250px"></el-input>
      </el-form-item>
      <el-form-item :label="$t('System.RunConfig.AlarmReportingPhoneNumber')" v-if="typeFlag1"   >
        <el-input v-model="form.reportTo" size="small" style="width: 250px" :placeholder="$t('System.RunConfig.reportToPlaceholder')"></el-input>
      </el-form-item>
      <!-- 保存按钮 -->
      <el-form-item label="">
        <el-button type="primary" @click="submitForm('form')" size="small">{{ $t("public.Save") }}</el-button>
        <el-button type="primary" @click="SMStest" size="small" style="margin-left: 5px">{{ $t("System.RunConfig.test") }}</el-button>
      </el-form-item>
    </el-form>
    <dialog-info :dialogInfo="visibaelFlag" @handleClose="handleClose" @determine="determine" :titleInfo="titleInfo" :width="'240px'" />
  </div>
</template>

<script>
import dialogInfo from '@/components/promptMessage/index'
import utils from "../../../../utils/index.js";
export default {
  components: { dialogInfo },
  data() {
    function validateIP(rule, value, callback) {
      var IPRegex =
        /^(25[0-5]|2[0-4]\d|1\d{2}|[1-9]\d|[1-9])\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))$/
      let valdata = value.split('\n')
      valdata.forEach((item) => {
        if (!IPRegex.test(item)) {
          callback(new Error(this.$t('System.RunConfig.IPReg')))
          return
        }
      })
      callback()
    }
    // 验证正整数
    const validMobile = (rule, value, callback) => {
      var myreg=/^[1-9]\d*$/;
      if(value.length!=11){
        callback(new Error("仅允许输入11位正整数"));
      }else if (!myreg.test(value)) {
       callback(new Error("仅允许输入11位正整数"));
     }else {
       callback();
     }
    };
    return {
      typeFlag1: true,
      typeFlag2: false,
      visibaelFlag: false, //弹框
      titleInfo: '',
      form: {
        plat: 'jindi', //短信平台
        address: '', //服务器地址
        port: '', //端口
        account: '', //账号
        pass: '', //密码
        mobile: '', //测试号码
        reportTo: '', //告警报送手机号
        AccessKeyID:'',//新增AccessKey ID：必填项，不限制特殊字符与长度
        AccessKeySecret:'',//新增AccessKey Secret：必填项，不限制特殊字符与长度；
        Sign:'',//新增签名：必填项，不限制特殊字符，最多支持12个字符；
      },
      typeOptions:[
        {
          type:'jindi',
          label:'金笛'
        },   
        {
          type:'ali',
          label:'阿里云短信'
        },
      ],
      rules: {
        // 地址
        address: [
          { validator: (rule, value, callback)=> {
              // var IPRegex =
              //   /^(25[0-5]|2[0-4]\d|1\d{2}|[1-9]\d|[1-9])\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))$/
              // let valdata = value.split('\n')
              value.split('\n').forEach((item) => {
                if (!/^(25[0-5]|2[0-4]\d|1\d{2}|[1-9]\d|[1-9])\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))$/.test(item)) {
                  callback(new Error(this.$t('System.RunConfig.IPReg')))
                  return
                }
              })
              callback()
            }, 
            trigger: 'blur' 
          },
          { required: true, trigger: 'blur', message: this.$t('System.RunConfig.IPVoid') }
        ],
        // 端口
        port: [{ required: true, message: this.$t('System.RunConfig.portReg'), trigger: 'blur' }],
        //新增AccessKey ID：必填项，不限制特殊字符与长度
        AccessKeyID: [
          { required: true, trigger: 'blur', message: this.$t('System.RunConfig.AKIDVoid') },
          { min: 1, message: this.$t('System.RunConfig.AKIDVoid'), trigger: 'blur' }
        ],
        //新增AccessKey Secret：必填项，不限制特殊字符与长度；
        AccessKeySecret: [
          { required: true, trigger: 'blur', message: this.$t('System.RunConfig.AKSecretVoid') },
          // { min: 1, message: this.$t('System.RunConfig.AKSecretVoid'), trigger: 'blur' }
        ],
        //新增签名：必填项，不限制特殊字符，最多支持12个字符；
        Sign: [
          { required: true, trigger: 'blur', message: this.$t('System.RunConfig.SignVoid') },
          { min: 1, max: 12, message: this.$t('System.RunConfig.SignStr'), trigger: 'blur' }
        ],
        mobile: [
          // { trigger: 'blur', message: this.$t('System.RunConfig.SignVoid') },
          // {  validator: validMobile, trigger: 'blur' },
        ],
      }
    }
  },
  watch: {
    'form.mobile' (val) {
      this.form.mobile = val.replace(/\D/g, '')
    }
  },
  mounted() {
    this.SMSlist()
  },

  methods: {
    // 进入页面接口请求
   async SMSlist(){
      var param = {}
      // console.log(data);
      const res = await this.$axios.post(
        'httpServe/sms/gateway/selectOne',
        param,
        true
      )
      console.log(res.data.plat,'查询短信网关配置')
      // this.form = res.data
      if(res.data.plat=='ali'){
        this.form.plat= res.data.plat //短信平台
        this.typeFlag1 = false
        this.typeFlag2 = true
        this.form.AccessKeyID=res.data.account//新增AccessKey ID：必填项，不限制特殊字符与长度
        // this.form.AccessKeySecret=res.data.pass,//新增AccessKey Secret：必填项，不限制特殊字符与长度；
        this.form.Sign=res.data.signName//新增签名：必填项，不限制特殊字符，最多支持12个字符；
        this.form.mobile= res.data.mobile //测试号码
      }
      if(res.data.plat=='jindi'){
        this.form.plat= res.data.plat //短信平台
        this.typeFlag1 = true
        this.typeFlag2 = false
        this.form.address= res.data.address,//服务器地址
        this.form.port= res.data.port //端口
        this.form.account= res.data.account //账号
        this.form.pass= res.data.pass //密码
        this.form.mobile= res.data.mobile //测试号码
        this.form.reportTo= res.data.reportTo //告警报送手机号
      }
      console.log(res.data, 'jjjjjj')
    },
    change(e) {
      console.log(e, "目前的选中值e111111111111111111111");
      if (e == 'ali') {
        this.typeFlag1 = false;
        this.typeFlag2 = true;
        // this.form.AccessKeySecret=''
        this.$nextTick(() => {
          this.$refs.form.clearValidate();
        }, 0);
        console.log(this.$refs, "jjjhghihjhkjllj");
      } else if (e == 'jindi') {
        this.typeFlag1 = true;
        this.typeFlag2 = false;
        this.$nextTick(() => {
          this.$refs.form.clearValidate();
        }, 0);
        console.log(this.$refs, "jjjhghihjhkjllj");
      }

      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      }, 0);
    },
    // 保存
    async save() {
      if(this.form.plat=='jindi'){
        if (this.form.port > parseInt(65535) || this.form.port < parseInt(1)) {
          this.$message({
            message: this.$t('System.RunConfig.PortRange'),
            type: 'error',
            offset: 100
          })
          return false
        }
      }
      let secretKey = $.getSecretKey(this.form.account)
      let jindiPass=utils.common.encrypt(this.form.pass, 'SM4', secretKey)
      let secretKeyAli= $.getSecretKey(this.form.AccessKeyID)
      let aliPass=utils.common.encrypt(this.form.AccessKeySecret, 'SM4', secretKeyAli)
      console.log(aliPass,'保存----aliPassaliPass');
      let params = {
        plat: this.form.plat,
        address: this.form.address,
        port: parseInt(this.form.port),
        account: this.form.plat=='jindi'?this.form.account:this.form.AccessKeyID,
        pass: this.form.plat=='jindi'?jindiPass:aliPass,
        // pass: this.form.pass,
        // "account":"阿里云秘钥",
			  // "pass":"阿里云密匙",
			  signName:this.form.Sign,
        mobile: this.form.mobile,
        reportTo: this.form.reportTo
      }
      console.log(params,'---保存')
      const res = await this.$axios.post(
        '/httpServe/sms/gateway/update',
        params,
        true
      )
      console.log(res)
      // this.titleInfo = "保存成功";
      // this.visibaelFlag = true;
      this.$message({
        message: this.$t('public.SuccessfullySaved'),
        type: 'success',
        offset: 100
      })
      this.form.AccessKeySecret=''
    },
    // 测试
    async SMStest() {
      if(this.form.plat=='jindi'){
        if (this.form.pass == undefined || this.form.pass == '') {
         this.$message({
           message: this.$t('System.RunConfig.TestFailed'),
           type: 'error',
           offset: 100
         })
         return false
        }
      }
      let secretKey = $.getSecretKey(this.form.account)
      let jindiPass=utils.common.encrypt(this.form.pass, 'SM4', secretKey)
      let secretKeyAli= $.getSecretKey(this.form.AccessKeyID)
      let aliPass=utils.common.encrypt(this.form.AccessKeySecret, 'SM4', secretKeyAli)
      console.log(aliPass,'测试----aliPass');
      let params = {
        plat: this.form.plat,
        address: this.form.address,
        port: parseInt(this.form.port),
        account: this.form.plat=='jindi'?this.form.account:this.form.AccessKeyID,
        pass: this.form.plat=='jindi'?jindiPass:aliPass,
        // pass: this.form.pass,
        // "account":"阿里云秘钥",
			  // "pass":"阿里云密匙",
			  signName:this.form.Sign,
        mobile: this.form.mobile,
        reportTo: this.form.reportTo
      }
      console.log(params, '---测试')
      const res = await this.$axios.post(
        '/httpServe/sms/gateway/test',
        params,
        true
      )
      console.log(res,'-----------');
      if (res.code == 200) {
        this.$message({
          message: this.$t('System.RunConfig.check'),
          type: 'success',
          offset: 100
        })
      }
      console.log(res, '-----ceshi')

      // this.titleInfo = "请查看填写的测试号码是否收到测试短信，若收到则测试成功！";
      // this.visibaelFlag = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        // this.save();
        if (valid) {
          this.save()
        } else {
          console.log('error submit!!')
          return false
        }
      })
      // this.$emit("")
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    handleClose() {
      this.visibaelFlag = false
    },
    determine() {
      this.visibaelFlag = false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
::v-deep .el-form-item {
  margin-bottom: 17px !important;
}
</style>
